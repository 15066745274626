import React from 'react'
import '../styles/about.css'
import Img from 'gatsby-image'

const EmployeeCard = props => {
  return (
    <div className="personal-card">
      <Img className="employee-image" fluid={props.image}></Img>
      <p className="name">{props.name}</p>
      <p className="role">{props.role}</p>
    </div>
  )
}

export default EmployeeCard
